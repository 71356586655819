body{
    color:'#f1f1f1 ';
}

.home-news-slider{
  display: grid;
  position: relative;
  /* overflow: hidden; */
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
  max-width: 80%;
  margin-left: 10%;
}
.home-news-image{
  
  position: relative;
  border-radius: 7px;
}
.home-news-image img{
  width: 32rem;
  position: relative;
  height: 20rem;
  margin-right: 3rem;
  border-radius: 8px;
}
.home-news-image::before{
  content: '';
  width: 35rem;
  background-color: rgba(220, 174, 76, 0.9);
  transform: rotateY(25deg);
  height: 22rem;
  position: absolute;
  top: -1rem;
  left: -1rem;
  z-index: -2;
  border-radius: 1rem;
}

.home-news-card{
  margin-top: 5rem;
  color: rgb(79, 60, 69);
  display: flex;
  align-items: center;
}

.home-news-card:nth-child(odd){
  flex-direction: row-reverse;
  
}


.home-news-text{
  margin-top: 2.5rem;
}
.home-news-title {
  background: #dab565;
  height: 4rem;
  width: 14rem;
  margin-inline: auto;
  display: grid;
  align-items: center;
  transform: skew(-15deg);
  border-radius: 4px;
}
.home-news-title >h1{
  transform: skew(+15deg);
}
.home-news-text-title{
  background: #dab565;
  height: fit-content;
  width: 80%;
  overflow-wrap: anywhere;
  margin-left: 10%;
  display: grid;
  align-items: center;
  transform: skew(-15deg);
  border-radius: 4px;
  margin-bottom: 1rem;
}
.home-news-text-title h1{
  font-size: 1.4rem;
  line-height: 1.45;
  transform: skew(+15deg);
  padding: 0.4rem;
}
.home-news-text-paragraph{
  margin-top: 2rem;
}


@media only screen and (min-width:320px) and (max-width:768px) {
  
  .home-news-card ,.home-news-card:nth-child(odd){
    flex-direction: column;
    
    align-items: center;
  }
  /* .home-news-card:nth-child(odd){
    flex-direction: row-reverse;
    
  } */
  .home-news-image img{
    width: 20rem;

    height: 15rem;
    margin-right: 0;
    border-radius: 8px;
  }
  .home-news-image::before{
    width: 22.5rem;
    background-color: rgba(220, 174, 76, 0.9);
    transform: rotateY(25deg);
    height: 17rem;
    position: absolute;
    top: -1rem;
    left: -1rem;
    z-index: -2;
  }
  
}