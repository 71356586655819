.manage-ads-list{
    margin-block:3rem;
    margin-inline:2rem;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(260px,1fr));
    gap: 1rem;
    position: relative;
}
.manage-ads-page h1 {

    margin-top: 1rem;
    text-align: center;
}
.ad-box{
 
    display: grid;
    align-content: space-between;
}
.ad-box h3 ,.ad-box h4 , .ad-box p {
    text-align: center;
}
.edit-ad-form{
    text-align: right;
}
.edit-ad-form input, .edit-ad-form textarea{
    text-align: right;
}
.edit-ad-btns{
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    gap: 1rem;
}
.loading{
    position: absolute;
    top: 50%;
    left: 55%;
}