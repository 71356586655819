

.create-product,.create-product input,.create-product textarea{
    text-align: end;
}
.create-product{
    border: solid 1px #2c3639;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px #2c3639;
    color: #2c3639;
    width: 60vw; 
    margin: 5vh 5vw  ;
    height: 80vh;
    display:grid;
    grid-template-columns:3fr 4fr;
    gap:1rem;
}



 .products-grid a{

    text-align: end;
    background-color: var(--primary-color);
    padding: 16px;
    color: aliceblue;
    border-radius: 6px;
    text-decoration: none;

   
} 


.product-description{
    display: grid;
    gap: 1rem;
}
.product-description h3{
margin: 1rem 0;
}
product-description{
    height: 35ch;
    overflow: scroll;
}
.product-description::-webkit-scrollbar{
display: none;
}

.product-description ul{
display: grid;
gap: 0.8rem;
}

.product-description ul li{
    list-style: none;
  
}
.product-description ul li:nth-last-child(1){
    margin-bottom: 1rem;
} 

.products-grid{
    margin-top: 1rem;
    margin-left: 2.5%;
    display: grid;
    width: 95%;
    gap: 2rem;
    margin-bottom: 5rem;
    text-align: center;
    grid-template-columns: repeat(auto-fit,minmax(320px,1fr));
}
.products-grid img{
    width: 80%;
    object-fit: cover;
    margin-top: 1rem;
}



/*SECTION PRODUCT SUMMARY*/
.product-summary-page{
    display: grid;
    justify-content: center;
    align-items: center;
}
.product-summary img{
    width: 20rem;
    height: 25rem;
}
.product-summary{
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 9fr;
    gap: 2rem;
    width: 100%;
}
.product-summary button{
    width: 10rem;
}

.product-sum-btns {
margin-top: 1rem;
display: grid;
gap: 1rem;
}
.product-sum-image label{
    width: 8rem;
}
.product-sum-gen{
    display: grid;gap: 1rem;
    grid-template-rows: 1fr 4fr 4fr 1fr;
    
}
.product-sum-gen p{
    height: 35ch;
    overflow: scroll;
}
.product-sum-gen p::-webkit-scrollbar{
    display: none;
}
.product-sum-text{
    border-radius: 8px;
    color: var(--bg-color);
    background-color: var(--primary-color);
    padding: 2rem;
    width: fit-content;
    text-align: end;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
}
.edit-product-form{
    direction: rtl;
    display: grid;
    width: 40vw;
    gap: 3vw;
    grid-template-columns: 2fr 2fr;
}
@media only screen and (min-width: 300px) and (max-width: 768px) {  

    .products-page {
        width: 100%;
    }
    .product-sum-text {
        grid-template-columns: 1fr;
        width: 90%;
        margin-left: 5%;
        text-align: center;
    }
    .product-summary-page{
        margin-bottom: 5rem;
    }
    .product-summary{
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .products-grid a{
        text-align: center;
    }
    /* .products-grid a p {
        height: 15rem;
        margin: 5rem 0;
    } */
   
    .edit-product-form {
        direction: rtl;
        display: grid;
        width: 80vw;
        gap: 3vw;
        grid-template-columns: 1fr;
    }
.create-product{
    width: 90%;
    height: 110%;
    margin: 1.5rem 0.9rem;
    margin-bottom: 5rem;
    inset: 0 0 0 0;
    display:flex;
    flex-direction: column-reverse;
    padding-bottom: 7rem;
}
.container{
    display: contents;
}

}
