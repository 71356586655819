.auth-form {
    max-width: 340px;
    margin: 60px auto;
    padding: 40px;
    border: 1px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  }
  .signup-form{
    color: #ddd;
    background: var(--primary-color);
    text-align: center;
    border-radius: 8px;
    
  }