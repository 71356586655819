
.about-page {
    margin-top:10rem ;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5rem;
}
.about-page-content{
    display: grid;
    gap: 6rem;
}
.certificates{
    margin-top: 3rem;
}

.about-factory{
    text-align: center;
}
.production-power{
    text-align: right;
}

.certificates-header{
    text-align: center;
}
.certificates-section-home{
    display: flex;
    justify-content: space-around;
}

.certificates-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.certificates img{
    height: 26rem;
    position: relative;
    bottom: 1rem;
    border-radius: 8px;
}

.about-page h1 {
    position: relative;
    display: flex;
    justify-content: center;
    top: -.4rem;
    margin-bottom: 2rem;
}

.about-page h1 + p{
text-align: end;
width: 80%;
margin-left: 7.5%;

}
.about-header {
    position: relative;
    display: flex;
    margin-bottom: 2.4rem;
    justify-content: center;
}
.about-header::before{
    content: '';
    height: 5rem;
    width: 19rem;
    background-color: rgba(220, 174, 76, 0.9);
    /* transform: rotateY(-25deg); */
    position: absolute;
    z-index: -2;
    align-self: center;

  
    border-radius: 4px;
    transform: skew(-15deg) rotateX(45deg) ;
}
/*
    background-color: #dab565;
    transform: skewX(-15deg);
    height: 3rem;
    position: relative;
    margin-left: 5%;
    margin-top: 2rem;
    width: 90%;
    display: grid;
    align-items: center;
    justify-items: center;*/


    @media only screen and (min-width:320px) and (max-width:768px) {
        .certificates-wrapper{
            
            margin-top: 7rem;
            gap: 6rem;
    grid-template-columns: 1fr;
        }
      
        .about-page h1 + p {
            text-align: center;
            width: 80%;
        }

        .certificates img{
            height: 25rem;
            width: 16rem;
        }
    }