

:root{
    --primary-color: #021e24;
    --secondary-color: #22616f;
    --tertiary-color: #fff;
    --button-radius:0.5em;
    --input-radius:0.25em;
}
.login-form{
    text-align: center;
    align-items: center;
    width: 20em;
    display: flex;
    flex-direction: column;
    margin:auto ;
    background-color: #021e24;
    border-radius: 0.8em;
    padding: 1em;
}
.login-form input, .login-form button{
    width: 100%;
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
}
.login-form label{
    font-size: 1em;
    font-family: 'Roboto', sans-serif;
    color: #fff;
}
.login-form input{
    
    padding: 0.5em;
    border-radius: var(--input-radius);
    border: none;
    outline: none;
}
.login-form button{
    
    max-width: 10rem;

    padding: 0.5em;
    border-radius: var(--button-radius);
    border: none;
    outline: none;
    background-color: #478fa0;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
.login-form button:hover{
    background-color: #22616f;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
.login-page{
    display: grid;
    
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
#recaptcha-continer{
    background-color: #021e24;
    color: #000;
}
#rc-anchor-continer *{
    background-color: #021e24;
    width: 6rem;
}
.rc-anchor-center-container label{
 color:teal;
}
.rc-anchor-light{
    color: teal;
}