.ads-page{
    margin-top: 10rem;
    min-height: 40vw;
}

.ads-box{
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr ;
}
.ad{
    margin: 1rem;
    min-height: 16rem;
    display: grid;
    align-content: space-evenly;
}
.ad p{
    text-align: right;
}
.ad h4{
    width: fit-content;
    height: fit-content;
}
.ad-header{
    text-align: right;
    width: fit-content;
    height: fit-content;
    margin-left: auto;
}
.ad h4{
    transform: skew(-8.5deg);
}
@media only screen and (min-width:320px) and (max-width:768px) {
    .ads-box{
        grid-template-columns: 1fr;
    }
    .ad{
        justify-items: center;
    }
    .ad-header{
            margin-left: unset;
    }
}