/* 
.klf-main-div{
    display: flex;
    justify-content:space-between ;
    margin-top: 5rem;
    width: 80%;
    margin-left: 10%;
    direction: rtl;
}
.klf-image-triangles {
    display: grid;
    gap: 1rem;
}
.klf-image-triangles img{
    width:12rem;
    max-height:12rem ;
}
.first-img-triangles{
    direction: ltr;

}
.first-img-triangles img:nth-child(1){
    position: relative;
    left: 5rem;
   }
.first-img-triangles img:nth-child(2){
 position: relative;
}
.first-img-triangles img:nth-child(3){
    position: relative;
    left: -5rem;
   }
.second-img-triangles img:nth-child(1){
position: relative;
left: -10rem;
}
.second-img-triangles img:nth-child(2){
    position: relative;
    left: -5rem;
    }
.klf-hero-text{
    margin-top: 1.5rem;
    width: 40%;
    direction: rtl;
}
.klf-hero-text h4{
    font-family: 'kufi-regular';
    font-size: 2.5rem;
    margin-bottom: 1rem;
}
.klf-hero-text p{
    font-family: 'kufi-regular';
    font-size: 1.2rem;
    font-weight: 400;
} */
.klf-hero{
    display: flex;
    justify-content:space-between ;
    flex-direction: row-reverse;
    margin-top: 5rem;
    width: 90%;
}
.klf-hero-img-triangles {
    display: grid;
    gap: 1rem;
}
.klf-hero-img-triangles img{
    width:12rem;
    max-height:12rem ;
}
.first-row-triangles{
    display: flex;
    width: 100%;
    max-width: fit-content;
}
.first-row-triangles img:nth-child(2){
 position: relative;
 left: 5rem;
}
.first-row-triangles img:nth-child(1){
    position: relative;
    left: 10rem;
}
.second-row-triangles{
position: relative;
left: 25%;
}
.second-row-triangles img:nth-child(1){
position: relative;
left: 5rem;
}
.klf-hero-text{
    margin-top: 3rem;
    width: 40%;
    direction: rtl;
}
.klf-hero-text h4{
    font-size: 2rem;
    margin-bottom: 1rem;
}
.klf-hero-text p{
    font-weight: 300;
}

@media only screen and (min-width:320px) and (max-width:884px) {
    
.klf-hero{
    width: 85%;
    position: relative;
    left: 8%;
    text-align: center;  
    display: grid;
    margin-top: 1rem;
}
.klf-hero * img:nth-child(n){
    width: 6.5rem;
    max-height: 6.5rem;
    position: relative;
    left: 0;
    right: 0;
}
.klf-hero * div{
    max-height: fit-content;
}

.klf-hero-text{
    width: 90%;
    position: relative;
    left: 5%;
}
.klf-hero-img-triangles{
    margin-top: 2rem;
    align-self: center;
    width: 100%;
    position: relative;
    left: 7.5%;
}
.first-row-triangles img:nth-child(1){
    position: relative;
    left: 2rem;
}
.first-row-triangles img:nth-child(3n){
    position: relative;
    left:-2rem;
}

.second-row-triangles{
    left: -1rem;
}
.second-row-triangles img:nth-child(1){
    left: 2rem;
}

}