.project-list {
    margin-top: 1rem;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 20px;
    margin-bottom: 5rem;
    width: 85%;
    margin-left: 7.5%;
  }
  .project-list a {
    background-color: var(--primary-color);
    position: relative;
    width: fit-content;
    padding: .4rem;
    display: block;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }
  .project-list h4 {
    font-size: 0.9em;
    color: var(--heading-color);
  }
  .read-more{
    background: none;
    border: none;
    color: purple;
    font: 1rem "kufi-regular";
    cursor: pointer;
  }
  .project-list p {
    color: var(--text-color);
    font-size: 0.9em;
  }
  .project-list .assigned-to {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #eee;
  }
  .project-list ul {
    margin: 10px 0;
    display: flex;
  }
  .project-list li {
    margin-right: 10px;
  }
  .project-list .avatar {
    width: 30px;
    height: 30px;
  }

  @media only screen and (min-width:320px) and (max-width:768px) {
    .project-list, .project-list div {
      text-align: center;
  }
    
  }