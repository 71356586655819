.news-details {
  display: grid;
  grid-template-columns: 4fr 2fr;
  align-items: start;
  grid-gap: 60px;
  text-align: end;
  margin-bottom: 5rem;
}

/* news summary */
.news-sum-page{
  display: grid;
    height: 95vh;
    margin: 2rem 1rem;
}
.news-summary {
  display: grid;
  position: relative;
  background-color: var(--primary-color);
  padding: 30px;
  border-radius: 4px;
}
.buttons:nth-last-child(1) {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}
.buttons{
  display: flex;
  gap: 2em;
  height: 3rem;
}
.news-sum-text p{
  display:-webkit-box;
  -webkit-line-clamp: 8;
  text-overflow: ellipsis;
  overflow: scroll;
  height: 20ch;
  text-align: end;
  position: relative;
  top: -2rem;
  direction: rtl;
}
.news-sum-image{
  display: grid;
}
.news-sum-image h2{
color: var(--bg-color);
}
.news-summary input, .news-summary textarea{
  text-align: end;
}
.news-summary .due-date {
  margin: 10px 0;
  font-size: 0.9em;
  color: var(--title-color);
}
p.news-details::-webkit-scrollbar {
  display: none;
}
.news-summary p {
  color: var(--bg-color);
}
.news-summary .details {
  margin: 30px 0;
  color: var(--text-color);
  line-height: 1.8em;
  font-size: 0.9em;
}
.news-summary h4 {
  color: var(--text-color);
  font-size: 0.9em;
}
.news-summary .assigned-users {
  display: flex;
  margin-top: 20px;
}
.news-summary .assigned-users .avatar {
  margin-right: 10px;
}
.news-summary + .btn {
  margin-top: 20px;
}

/* news comments */
.news-comments label {
  margin-bottom: 0px;
}
.news-comments textarea {
  min-height: 40px;
  font-size: 1.5em;
}

/* comment list */
.news-comments h4 {
  color: var(--heading-color);
}
.news-comments li {
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
  box-shadow: 3px 3px 5px rgba(0,0,0,0.05);
  background: #fff;
}
.comment-author {
  display: flex;
  align-items: center;
  color: var(--title-color);
}
.comment-author .avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.comment-date {
  color: var(--text-color);
  font-size: 0.9em;
  margin: 4px 0 10px;
}
.comment-content {
  color: var(--text-color);
  font-size: 0.9em;
}

@media only screen and (min-width:320px) and (max-width:768px) {
 .news-details{
  grid-template-columns: 1fr;
 }
  
}