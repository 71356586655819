.about-page-edit{
    border: solid 1px #2c3639;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px #2c3639;
    color: #2c3639;
    width: 60vw;
    
    height: fit-content;
    margin: 5vh 13vw;
    text-align: center;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr ;
    gap: 1.5rem;

    
}
.about-page-edit-content{
    border: solid 1px #2c3639;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px #2c3639;
    color: #2c3639;
    width: 60vw;
    
    height: fit-content;
    margin: 5vh 13vw;
    text-align: center;
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr ;

}
.first-certificate-img , .second-certificate-img{
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}
#about-klf{
    display: block;
}
.about-page-edit input{
display: none;
}
.about-page-edit img{
    width: 10rem;
    height: 18rem;
    margin-top: 1rem;
    border-radius: 7px;
}


.about-page-edit-content input{
    text-align: end;
    margin: 1rem;
    display: block;
}

.about-page-edit-content textarea{
    width: 25vw;
    min-height: 6rem;
    resize: none;

    text-align: end;
    margin: 1rem 0;
}


@media only screen and (min-width: 300px) and (max-width: 768px) {
    .about-page-edit{
        grid-template-columns: 1fr;
        padding-bottom: 5rem;

     }
     .about-page-edit-content{
        grid-template-columns: 1fr;
     }
}