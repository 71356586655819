.certificates-home{
    margin-top: 10rem;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 5rem;
    text-align: end;

}
.loading-style{
    position: absolute;
    top: 45%;
    left: 45%;
    font-size: 1.3em ;
}

@media  only screen and (min-width:320px) and (max-width:768px) {
    .loading-style{
        font-size: .7em ;
    }
    
}