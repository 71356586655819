/*SECTION ___________________Footer Design__________________*/
footer{
    background: linear-gradient(to right top, rgb(218 181 101) 0%, rgb(218 181 101) 50%, rgba(247,58,34,0) 50%, rgba(233,72,58,0) 100%);
    margin-top: 4rem;
    background-repeat: no-repeat;
    background-origin: border-box;
    background-size: contain;

}
.footer-divider{
    background-color: #dab565;
    transform: skewX(-15deg);
    height: 3rem;
    position: relative;
    margin-left: 5%;
    margin-top: 2rem;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer-divider > h4{
    color: #4F3C45;
    font-weight: 600;
    position: absolute;
    font-size: 2rem;
    transform: skewX(+15deg);
    
}
.footer-divider img{
    width: 90%;
}
iframe{
    align-self: center;
}
.getdirections{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    align-self: center;
    margin-left: 5%;
}
.getdirections p{
font-size: 1.4rem;
width: 60%;
direction: rtl;
position: relative;
left: 20%;
}
.get-directions-button{
color: #dab565;
background-color: #4F3C45;
font-size: 1.8rem;
font-weight: 600;
width: 75%;
position: relative;
border: none;
border-radius: 15px;
padding: 25px;
transition: 0.35s;}
.get-directions-button:hover{
    background-color: #F0F0F0;
    color: #4f3c45;
    

}

.social-media-icons{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    justify-items: stretch;
    left: 0;
}

.social-media-icons > * {
    filter: opacity(0.80);
    transition: 0.3s;
}
.social-media-icons > *:hover{
    transition: 0.3s;
    filter: opacity(1);
}

.directions-social img{
    width: 8rem;
    height: 8rem;
}
.email-number-table{
   direction: ltr;
}
.email-number{
    display: grid;
    gap: 1rem;
    align-self: center;
}
.email-number a{
    text-decoration: none;
    color: #000;
    font: 1.4em 'poppins';
}

.directions-social{
    width: 90%;
    margin-left: 5%;
    margin-top: 5rem;
    display: flex;
    justify-content: space-around;
}

.contact-us{
    margin-top: 2.7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.end-footer{
    display: flex;
    justify-content: space-around;
    margin-top: 5rem;
}
.road-map-span{
margin-top: .35rem;
border-bottom:solid 2.4px #2c3639 ;
}
.end-footer{
    align-items: center;
}
.end-logo{
    display: grid;
    justify-items: center;
    gap: 1rem;
    position: relative;
    bottom: 1.5rem;
}
.end-logo img{
    width: 10rem;
}
.road-map-table td{
    font-size: 1.2rem;
}

.road-map-table{
    direction: rtl;
}



.hide-left{
opacity: 0;
transition: all 1s;
filter:blur(5px) ;
transform: translateX(-100%);
-webkit-transform: translateX(-50%);
}

@media only screen and (min-width:320px) and (max-width:768px) {
    
/* .footer-divider h4{
    font-size: 1.5rem;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -1rem;
} */



.social-media-icons{
    display: inline-grid;
    grid-template-columns: .5fr 1fr;
    gap: 1rem;
    align-items: end;
    align-content: space-between;
    justify-items: stretch;
    position: relative;
    flex-direction: row;

}
.social-media-icons img{
    width: 6rem;
    height: 6rem;
    margin: 0 1rem;
}
.email-number-table td span{
    font-size: 1.4rem;
    font-weight: 600;

}

.directions-social{
    display: grid;
    width: unset;
    align-items: center;
    text-align: center;
    margin: 0;
    margin-top: 2rem;
    width: 100%;
    /* height: 100%; */
    justify-content: center; 
    /* gap: 1rem;*/
}
.getdirections{
    margin-top: 2rem;
    width: 90%;
    /* height: 3rem; */
    border-radius: .5rem;
    left: 0;
    display: grid;
    justify-content: center;
    text-align: center;
    font-size: 1rem;
    justify-items: center;
}
.getdirections p{
    width:unset;
    left: unset;

}
.get-directions-button{
    font-size: 1em;
    left: 0;
    align-self: start;
    width: 100%;
    position: relative;
    border: transparent;
    border-radius: 15px;
    padding: 18px;
}
.end-logo p{
    text-align: center;
}

 .hide-left{
    opacity: 0;
    transition: all 1s;
    filter:blur(5px) ;
    transform: translateX(-100%);
    -webkit-transform: translateX(-50%);
    }
    
 .hide-right{
    opacity: 0;
    transition: all 1s;
    filter:blur(5px) ;
    transform: translateX(+100%);
    -webkit-transform: translateX(+50%);
    }
    .show{
        opacity: 1;
        filter: blur(0);
        transform: translateX(0);
        -webkit-transform: translateX(0);
        
        }
}