/* 
.hero-div {
  width: 100%;
  height: 100vh;
  background: url("../../../../../assets/images/hero@3x.png");
  background-size: cover;

}

.hero-box {
  display: grid;
  gap: 2rem;
  text-align: end;
  width: 42rem;
  height: max-content;
  position: relative;
  margin-left:auto  ;
  margin-right: 10rem;
  top: 10rem;
  background-color: rgba(30, 30, 30, 0.5);
  padding: 2.5rem;
  border-radius: 10px;
}
.hero-title {
  font-family: "kufi-regular";
  font-size: 2.5rem;
  color: var(--heading-color);
}
.hero-paragraph {
  font-family: "kufi-light";
  color: var(--bg-color);
}
.hero-buttons-div {
  display: flex;
  gap: 3rem;
}
.hero-buttons-div button{
    border-radius: 7px;
}
.hero-button-contact {
  font-size: 1.5rem;
  font-family: 'kufi-semibold';
  width: 160px;
  height: 50px;
  background: #dab565;
  border: solid;

}
.hero-button-contact:hover{
  background-color: black;
  color: #dab565;
  transition: all .25s;
}
.hero-button-learn {
  font-size: 1.5rem;
  color:var(--bg-color);
  font-family: 'kufi-light';
  width: 160px;
  height: 50px;
  border: solid  #dab569;

  background: transparent;
}
.hero-button-learn:hover{
    background-color: #dab565 ;
    color: black;
  transition: all .25s;

} 
*/

.hidden{
  opacity: 0;
  transition: all 1s;
  filter:blur(5px) ;
  transform: translateX(+100%);
  -webkit-transform: translateX(+50%);
}

/*   sdddddddddddddddddddd*/

.hero-section{
  margin: 0;
  min-width: 102vw;
  min-height: 100vh;
  min-height: 104dvh;
  background: url("../../../../../assets/images/hero@3x.png");
  background-size: cover;
  display: grid;
  align-items: center;
}


/* rgba(44,54,57, 0.3); #2c3639  // 218 181 101 #dab565 // 240 240 240  #F0F0F0 */
.hero{
  width: 60vw;
  text-align: right;
  border-radius: 15px;
  background-color: rgba(44, 54, 57, 0.543);
  position: relative;
  top: 2rem;
  right: 5%;
  padding: 2.2rem ;
 
  justify-self: end;
}
.hero h1{
  font-weight: 600;
  font-size:2.5rem;
  margin: 0;
  font-family: "kufi";
  color: #dab565;
}
.hero-paragraphs{
margin-top: 3rem;

display: grid;
gap: 1rem;
}
.hero-paragraphs p {
  font-size: 1.4rem;
  color: #F0F0F0;
  margin: 0;
  line-height: 1.7;

}
.hero-buttons{
  display: flex;
  gap: 4rem;
  margin-top: 2.5rem;
  
}
.hero-buttons a{
  text-decoration: none;
  -webkit-text-fill-color: none;
  
}
.hero-buttons button{
  font-size: 1.5rem;
  border: solid 2.5px #FAC95E;
  border-radius: 5px;
  
}
.hero-contactus-button{
  font-size: 1.5rem;
  font-family: 'kufi-semibold';
  width: 160px;
  height: 50px;
  background: #dab565;
  border: solid;
  color: black;
  
}

.hero-contactus-button:hover{
  background-color: black;
  color: #dab565;
  transition: all .25s;
}

.hero-knowmore-button{
  font-size: 1.5rem;
  color:var(--bg-color);
  font-family: 'kufi-light';
  width: 160px;
  height: 50px;
  border: solid  #dab569;
  background: transparent;
}
.hero-knowmore-button:hover{
  background-color: #dab565 ;
  color: black;
transition: all .25s;
}
.jumping-arrow{
  position: relative;
  bottom: 0;
  left: 45%;
}
.down-arrow{
  position: absolute;
  width: 3rem;
  animation: move 1.4s infinite ease-in-out;
}
@keyframes move{
  0%{top:0;}
  50%{top:1.5rem;}
  100%{top:0;}
}





@media only screen and (min-width: 300px) and (max-width: 768px) {
   

 
  .hero{
    font-size: 1rem;
    width: 85%;
    top: 5%;
    margin: 0;
    left:-3.2%;
    right: 0%;
    padding: 1rem;
    padding-block: 3rem;
  }
  .hero h1{
    font-size: 1.9rem;
  }
  .hero-paragraphs{
    margin-top: .5rem;
   
    display: grid;
    gap: 0.2rem;
    }
  .hero-paragraphs p {
        
        font-size: .75rem ;
        color: #F0F0F0;
        margin: 0;
        line-height: 1.8;
    
    }
  .hero-buttons{
    justify-content: space-around;
    gap: 1rem;
    top: 1.2rem;
  }
  .hero-buttons button{
    font-size: 1rem;
    padding: .5rem;
    height: fit-content;
  }
  .hero-buttons button:active{
    filter: sepia(0.7);
  
  }
  .down-arrow{
   width: 2rem;
  }
  
}
