@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'kufi-black';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Black.ttf");
}
@font-face {
  font-family: 'kufi-extrabold';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-ExtraBold.ttf");
}
@font-face {
  font-family: 'kufi-bold';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Bold.ttf");
}
@font-face {
  font-family: 'kufi-semibold';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-SemiBold.ttf");
}
@font-face {
  font-family: 'kufi-regular';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Regular.ttf");
}
@font-face {
  font-family: 'kufi-medium';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Medium.ttf");
}
@font-face {
  font-family: 'kufi-light';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Light.ttf");
}
@font-face {
  font-family: 'kufi-extralight';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-ExtraLight.ttf");
}
@font-face {
  font-family: 'kufi-thin';
  src: 
  url("./assets/fonts/kufi/static/NotoKufiArabic-Thin.ttf");
}
@font-face {
  font-family: 'stc';
  src: 
  url("./assets/fonts/stc-font.ttf");
}
@font-face {
  font-family: 'noto';
  src: 
  url("./assets/fonts/NotoSansArabic.ttf");
}


/* Small devices  For Mobile devices: 320px-480px */
/*For Tablets or iPad: 480px - 768px*/
/*For Laptop or small-size screen: 768px -1024px*/
/*For Desktop or large-size screen: 1025px -1200px*/
/*For Extra-large size device: 1200px and more*/
/* variables */
:root {
  --heading-color: #dab565 ;
  --text-color: #2c3639;
  --primary-color: #8d69f1;
  --highlight-color: #d13267;
  --bg-color: #f0f0f0;
  --hover-color:#4f3c45;
}
/* colors: #dab565  #4f3c45 #2c3639 #f0f0f0*/
/* base styles */
body {
  margin: 0;
  font-size: 1.1em;
  background: var(--bg-color);
}
 h1, h2, h3, h4 {
  font-family: 'kufi-regular';
  margin: 0;
  padding: 0;
}
ul, li, p{
  font-family: 'kufi-regular';
}
h1{
  color: #4f3c45;
}
ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}
.btn {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  max-height: 3rem;
  border: 1px solid var(--primary-color);
}
.btn:hover {
  color: #fff;
  background-color: var(--primary-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}
label span {
  display: block;
  margin-bottom: 6px;
}
input, textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}
textarea {
  min-height: 160px;
}
.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}

.hide-right{
  opacity: 0;
  transition: all 1s;
  filter:blur(5px) ;
  transform: translateX(+100%);
  -webkit-transform: translateX(+50%);
  }
  .show-right{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
  -webkit-transform: translateX(0);
  }



  .show{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
    -webkit-transform: translateX(0);
    }
    body{
      overflow-x: hidden;
    }