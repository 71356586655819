
  .products-public-page{
    margin-top: 10rem;
    display: grid;
    align-items: start;
    min-height: 100vh;
  }

  .products-header{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.products-header h1{
    color: #4F3C45;
    font-weight: 600;
    position: absolute;
    font-size: 2rem;
}
.products-header img, .certificates-tag img{
    width: 14rem;
}

.product-card p{
  color: #4F3C45;
  line-height: 1.65;
 
}
.prod-header-image{
  display: flex;
  flex-direction: column;

  position: relative;
}
.prod-header-image .path-51-icon{
  position: relative;
  top: -3rem;
  left: -1rem;
  height: 4rem;
  width: 21rem;
  z-index: -1;
}
.product-card{
  color: rgb(79, 60, 69);
  /* background: linear-gradient(to right bottom, rgb(218 181 101) 0%, rgb(218 181 101) 50%, rgba(247,58,34,0) 50%, rgba(233,72,58,0) 100%);

  background-repeat: no-repeat;
  background-origin: border-box;
  background-size: contain; */
  border-top-left-radius: 1rem;
  width: 80%;
  margin-top: 4rem;
  margin-left: 10%;
  display: grid;
  grid-template-columns: 1fr 9fr  ;

  gap: 2rem;
  align-items: stretch;
  direction: ltr;
  text-align: end;
}
.prod-text-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  direction: rtl;
  gap: 2rem;
  text-align: right;
}

.prod-uses-specs{
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.product-card:nth-child(odd){
  direction: rtl;
  text-align: start;

  border-bottom-right-radius: 1rem;

}
.product-card:nth-child(odd) .prod-header-image .path-51-icon{
left: 0rem;
top: -3rem;
}
.prod-header-image::before{
  content: '';
  width: 22rem;
  background-color: rgba(220, 174, 76, 0.9);
  transform: rotateY(25deg);
  height: 29rem;
  position: absolute;
  z-index: -2;
  left: -2rem;
  top:1.5rem;
  border-radius: 1rem;
  
}
.product-card:nth-child(odd) .prod-header-image::before{
  content: '';
  width: 22rem;
  background-color: rgba(220, 174, 76, 0.9);
  transform: rotateY(-25deg);
  height: 29rem;
  position: absolute;
  z-index: -2;
  right: -2.6rem;
  top:1.5rem;
  border-radius: 1rem;
  
}

.prod-header-image img{
  width: 18em;
  height: 26rem;
  
  /* border-radius: 7px; */
}

.prod-uses ul,.prod-specs ul{
  margin-top: 2rem;
  text-align: start;
  margin-bottom: 2rem;
  /* list-style-type:'-'; */
  text-indent: .4rem;
  direction: rtl;
  display: grid;
  gap: .8rem;
}

* {
  scroll-behavior: smooth;
}
.prod-description-process{
  display: grid;
  
}
.prod-description h2 ,.prod-process h2 ,.prod-uses h2,.prod-specs h2{
  margin: 1rem 0;
  line-height: 1;
}
.prod-description  ,.prod-process  ,.prod-uses ,.prod-specs {
  position: relative;
}

.prod-title  , .prod-process-title  ,.prod-uses-title  ,.prod-specs-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
 /*
.prod-title::before , .prod-process-title::before ,.prod-uses-title::before ,.prod-specs-title::before{
  content: attr(data-content);
  height: 3rem;
  width: 100%;
  background-color: rgba(220, 174, 76, 0.9);
  transform: rotateY(-25deg); 
  position: absolute;
  z-index: -2;
  /* align-self: center; */
  /* right:-4%; */
  /* top: 1%; 
  border-radius: 4px;
  transform: skew(-15deg) rotateX(45deg) ;

}
*/


@media only screen and (min-width:320px) and (max-width:768px) {
  
.product-card{
  display: grid;
  grid-template-columns: 1fr;
}
.prod-text-wrapper , .prod-text-wrapper ul , .prod-uses-specs{
  grid-template-columns: 1fr;
  text-align: center;
}
.product-card:nth-child(odd){
  direction: unset;
  text-align: center;
}
.prod-header-image::before{
  display: none;
}
.prod-header-image{
  border-top: solid 15px #dab565;
  border-left: solid 15px #dab565;
  border-bottom: solid 15px #dab565;
  border-radius: 15px;
}
.prod-title::before, .prod-process-title::before, .prod-uses-title::before, .prod-specs-title::before{
  display: none;
}
.product-card h2{
padding-block: .6rem;
transform: skew(0);
margin: auto;
}

.prod-description h2, .prod-process h2, .prod-uses h2, .prod-specs h2 {
  margin-block: 1rem;
}
}