

@media only screen and (min-width:320px) and (max-width:768px) {  
    .public-header{
        display: none;
    }
    .public-phone-header{
        position: fixed;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        /* top: 1vh; */
        /* left: 5%; */
        padding-block: 8px;
    }
    
    .phone-logo{
        margin-left: 5vw;
    }
    .phone-logo img{
        width: 6rem;
    }
    header{
        z-index: 12;
    }
    body{
        overflow-x: hidden;
    }
    .phone-navigation-nav{
        z-index: 777;
        position: absolute;
        transform: scale(0,0);
        transform-origin: top;

        display: grid;
        gap: 10px;    
        transition: transform 250ms ease-in-out;
        padding: 1rem;
        right: 2%;
        border-radius: 5px;
        background-color:#2c3639f1 ;
    }
    .phone-navigation-nav a{
        color: #dab565;
        text-decoration: none;
        font-size: 1.6rem;
        text-align: end;
    }
    .phone-navigation button{
        background: transparent;
        border: none;
        color: #dab565;
        margin-right: 3vw;
    }
    .phone-navigation button svg{
        width: 3rem;
        z-index: 777;
        height: 2.5rem;
    }
    .show_nav{
        transform: scale(1,1);
        transform-origin: top;
    }
    .phone-navigation-nav .active {
        color: #4f3c45;
      }
    
       
}

@media only screen and (min-width:769px) {
    .public-phone-header{
        /* display: none; */
        display: flex;
        z-index: 500;
        width: 100%;
        position: fixed;
        top: 0;
        justify-content: space-between;
        align-items: center;
        transition: backgound-color 350ms ease-in-out;
    }
    .phone-navigation{
        justify-content: space-between;
        display: flex;
        top: 0;
    }
    .phone-navigation-nav{
        position: relative;
        right: 2.5vw;
        display: flex;
        flex-direction: row-reverse;        gap: 1rem;
    }
    .phone-logo {
        position: relative;
        left: 2.5vw;
    }
    .phone-logo img{
        width: 8rem;
    }
    .phone-navigation button{
    display: none;
    }
    .phone-navigation-nav a:hover {
        opacity: 1;
        position: relative;
        /* top: 21.5px; */
        color: #4f3c45;
        transition:  color ease-in 280ms;
      }
      .phone-navigation-nav .active {
        color: #4f3c45;
      }
      
      .phone-navigation-nav a::after {
        content: '';
        display: block;
        height: 5px;
        background: #2C3639;
        position: absolute;
        bottom: -0.75em;
        left: 0;
        right: 0;
        transform: scale(0, 1);
        transition: transform ease-in-out 250ms;
      }

    .phone-navigation-nav a{
        color: #dab565;
        position: relative;
        text-decoration: none;
        font-size: 1.8rem;
        text-align: end;
    }
    .phone-navigation-nav a:hover::after {
        transform: scale(1,1);
        transition: transform ease-in-out 250ms ;

      }

   
}