.sidebar {
  width: 15vw;
  background: var(--primary-color);
  position: relative;
  color: #fff;
  min-height: 100vh;
  margin-right: 1rem;
}
.sidebar , .sidebar >*{
  z-index: 5;
}
.sidebar-content {
  
  position: fixed;
  width: inherit;
}
.sidebar .user {
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  padding:20px 20px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.598);
}
.sidebar .links {
  margin-top: 20px;
  margin-left: 20px;
}
.sidebar .links ul {
  display: grid;
  padding: 0;
}
.sidebar .links li:last-child {
  margin-top: auto;
}
.sidebar .links li a {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.sidebar .links a {
  padding: 10px;
  text-decoration: none;
  width: 100%;
  color: #fff;
  box-sizing: border-box;
}
.phoneLogOutBtn {
display: none;

}

.sidebar .links img {
  margin-right: 10px;
  filter: invert(100%);
}
.sidebar .links a.active {
  color: #555;
  background: var(--bg-color);
  border-radius: 20px 0 0 20px;
}
.sidebar .links .active img {
  filter: invert(40%);
}
@media only screen and (min-width: 300px) and (max-width: 768px) {
   
  
  .sidebar{
    width: 100%;
    position: fixed;
    bottom: 0;
    min-height: 4rem;
    margin: 0;
    display: flex;
  }
  .sidebar .user{
    display: none;
  }
  .sidebar-content {
    position: relative;
  }
  .sidebar .links{
    margin: 0;
    
  }
  .sidebar .links a{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content:center;
    padding: .5rem;
    width: 1rem;
    color: #ededed;
  }
  .sidebar .links  img {
    margin: 0;
    
  }
  .sidebar .links  a.active {
    border-radius:5px;
    width: 100%;
    margin: 0;
  }
  
  button.logOutBtn{
      display: none;   
  }
  .sidebar .links ul li a span{
    display: none;

  }
  .phoneLogOutBtn {
    border: none;
    background: none;
    display: block;
  }
  .phoneLogOutBtn  img{
  
    height: 2.4rem;
  }
  .sidebar .links img {
    filter: invert(100%);
  }
  .sidebar .links ul{
    position: relative;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;    
    justify-content:space-between ;
    gap: 0;
  }
  .sidebar-content nav ,.sidebar-content nav a  {
    transform: scale(1);
    margin: 0;
    top: 0;
    padding: 0;
    opacity: 1;
    bottom: 0;
    padding-bottom: 10px;
  }
  
  .sidebar .links li:last-child {
    margin:0 ;
}   
  

}