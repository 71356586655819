/* .news-page{
    width: 80%;
    display: grid;
    gap: 8rem;
    margin-left: 10%;
    margin-top: 20vh;
}
*/
.news-page-box{
    display: flex;
    align-items:flex-start;
    text-align: end;
    position: relative;
    height: fit-content;

}

.news-page{
    width: 80%;
    min-height:100vh;
    /* padding-inline: 10%; */
    margin-inline: 10%;
    display: grid;
    gap: 5rem;
    align-items: start;
}
.news-page-image-box::before{
    content: '';
    width: 43rem;
    background-color: rgba(220, 174, 76, 0.9);
    transform: rotateY(25deg);
    height: 25rem;
    position: absolute;
    top: 0rem;
    left: -3rem;
    z-index: -2;
    border-radius: 1rem;
}

.news-page-image-box{
    position: relative;
}

.news-page-box:nth-child(odd) .news-page-image-box::before{
    /* right:  -1rem; */
    left: 2rem;
}
.news-page-image-box img{
    width:40rem;
    height: 23rem;
    float: left;
    border-radius: .5rem;
    margin: 0.98rem;
}

.news-page-box-wrapper{
    margin: 150px auto;
    width: 80%;
    margin-left: 10%;
}
.news-page-text-box{
    margin-top: 1rem;
    color: #111; 
    font-size:0.9rem;
    display: grid;
    text-align: right;
    grid-template-rows: 2fr 6fr 2fr;
    justify-items: end;

}
.background-title{
    background-color: #dab565;
    text-align: center;
    padding-inline: 8px;
    border-radius: 5px;
    transform: skew(-15deg);
    width: 80%;
}
.background-title > h1{
    line-height: 1.55;
    font: 1em "kufi-medium";
    transform: skew(+15deg);
}
/* .news-page-text-box h1::before{
  content: '';
  height: 3rem;
  width: 14rem;
  background-color: rgba(220, 174, 76, 0.9);
  /* transform: rotateY(-25deg); 
  position: absolute;
  z-index: -2;
  align-self: center;
  right:-2%;
  top: -.6rem;
  border-radius: 4px;
  transform: skew(-15deg) rotateX(45deg) ;
} */
.news-page-text-box span{
    position: relative;
    font-size: 1.4rem;
    display: grid;
    height: fit-content;
    width: fit-content;
    align-items: center;
    margin-top: 1.5rem;
}

.news-page-news-title {
    height: fit-content;
    font-size: 1.4rem;
}
/* .news-page-text-box span::after{
  content: '';
  height: 2.6rem;
  width: 8rem;
  background-color: rgba(220, 174, 76, 0.9);
  /* transform: rotateY(-25deg); 
  position: absolute;
  z-index: -2;
  align-self: center;
  right:-1%;
  top: -0.5rem;
  border-radius: 4px;
  transform: skew(-15deg) rotateX(45deg) ;
} */
.news-page-text-box h1{

    position: relative;
    margin: 0 ;
}
.news-page-text-box p{
   margin-top: 1rem;
   font-size: 1rem;
   font-family: 'kufi-medium';
   line-height: 1.75;
}

h1{
    font-family: 'kufi-regular';
    line-height: 1;
}
.news_wrapper{
    position: relative;
    z-index: -1;
}
.news_divider {
position: absolute;
width: 100%;
height: 2rem;
bottom: -2rem;
}

.news-page-box:nth-child(even) {
    display: flex;
    gap: 6rem;
    flex-direction: row;
}


.news-page-box:nth-child(odd){
    display: flex;
    gap: 6rem;
    flex-direction: row-reverse;
 }




 @media only screen and (min-width:320px) and (max-width:768px) {
    
    .news-page{
        padding-inline: 0;
        align-items: center;
        justify-content: center;
        margin-left: 10%;
        justify-items: center;
    }
    
    .news-page-box:nth-child(even) {
        display: flex;
        gap: 0rem;
        flex-direction: column;
    }
    
    
    .news-page-box:nth-child(odd){
        display: flex;
        gap: 3rem;
        flex-direction: column;
     }
    .news-page-image-box img{
        width: 20rem;
        position: relative;
        /* left: 1rem; */
        height: 15rem;
        border-radius: 8px;
    }
    .news-page-image-box{
        display: flex;
        justify-content: center;
        align-items: center;        
    }
    
    .news-page-image-box::before{
        content: '';
        width: 22.5rem;
        background-color: rgba(220, 174, 76, 0.9);
        transform: rotateY(25deg);
        height: 17rem;
        position: absolute;
        /* top: -1rem; */
        left: -1rem;
        z-index: -2;
        border-radius: 1rem;
    }
    .news-page-box{
        display: grid;
        justify-content: center;
        align-items: center;

    }
    .news-page-box:nth-child(odd) .news-page-image-box::before{
        left: 1rem;
    }
    .news-page-text-box{
        gap: 1rem;
        margin-top: 1rem;
        color: var(--hover-color);
        font-size: 1.4rem;
        font-family: 'kufi-light';
        display: grid;
        grid-template-rows: 2fr 3fr 1fr;
        justify-items: center;
        align-items: center;
        text-align: center;
    }
    .news-page-text-box p{
        width: 90%;
        margin-left: 5%;
    }
    .news-page-box:nth-child(odd){
        display: grid;
        gap: 0rem;
    } 
    .news-page-image-box::before{
        display: none;
    }
    .news-page-image-box img{
        margin: 0;
        border-radius: 0;
    }
    .news-page-image-box{
        border-top: solid 15px #dab565;
    border-left: solid 15px #dab565;
    border-bottom: solid 15px #dab565;
    border-radius: 15px;
    }
    

 }