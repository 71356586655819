.home-prod-text-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    direction: rtl;
    gap: 2rem;
    text-align: right;
}
/* ^ for that 
              
display: grid;
*/
.home-product-card{
    color: rgb(79, 60, 69);
    display: flex;
    align-items: center;
    /*
    border-top-left-radius: 1rem;
    width: 80%;
    margin-top: 4rem;
    padding-inline: 10%;
    display: grid;
    grid-template-columns: 1fr 9fr;
    gap: 2rem;
    align-items: stretch;
    direction: ltr;
    text-align: end; */
}
.home-product-card:nth-child(odd){
    flex-direction: row-reverse;
}
.home-product-card:nth-child(odd) .home-prod-text-wrapper{
    direction: unset;
}
.home-product-card:nth-child(odd) .home-prod-text-wrapper .home-prod-description-process {
    display: grid;
    justify-items: end;
}
/* .home-products-header img{
    position: absolute;
    z-index: -8;
} */
/* 
 line 39 
 display: grid;
*/
.products-home-page{
    display: grid;
    gap: 3.5rem;
    width: 80%;
    margin-left: 10%;

}
.home-products-header{
    /* display: flex;
    align-items: center;
    margin-top: 4rem;
    justify-content: center; */
    background: #dab565;
    height: 4rem;
    width: 14rem;
    margin-inline: auto;
    display: grid;
    align-items: center;
    transform: skew(-15deg);
    border-radius: 4px;
}
.home-products-header > h1 {
    transform: skew(+15deg);
    margin-inline: auto;
}

/*
.home product card line 33
    gap: 5%;
    margin-block: 4rem
rem
;
    color: rgb(79, 60, 69);
    display: grid;
*/
.header-maker{
    background: #dab565;
    height: 4rem;
    width: 14rem;
    margin-inline: auto;
    display: grid;
    align-items: center;
    transform: skew(-15deg);
    border-radius: 4px;
}
.header-maker >h1{
    transform: skew(+15deg);
    margin-inline: auto;
}

.background-title >h2{
    transform: skew(+15deg);
}

.home-prod-header-image img{
    width: 18em;
    height: 26rem;
    margin-top: 3rem;
    border-radius: 7px;
}.home-prod-header-image{
    position: relative;
}
.home-prod-header-image::before {
    content: '';
    width: 22rem;
    background-color: rgba(220, 174, 76, 0.9);
    transform: rotateY(25deg);
    height: 29rem;
    position: absolute;
    z-index: -5;
    left: -2rem;
    top: 1.5rem;
    border-radius: 1rem;
}


@media only screen and (min-width:320px) and (max-width:768px) {
    .products-home-page{
        display: grid;
        grid-template-columns: 1fr;
        gap: 3rem;
        position: relative;
    }
    .home-prod-header-image img{
        z-index: 8;
        border-left: solid 15px #dab565;
        border-top: solid 15px #dab565;
        border-bottom: solid 15px #dab565;
    }
    .home-prod-header-image img {
        position: relative;
      
    }
    .home-prod-text-wrapper{
        grid-template-columns: 1fr;
        text-align: center;
        margin-top: 1.8rem;
    }
    .home-product-card{
    width: 85%;
    margin-left: 7.5%;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    }
    .prod-text-wrapper{
    grid-template-columns: 1fr;

    }
    .home-products-header img{
        position: absolute;
        z-index: -8;
    }
    .home-products-header{
        display: flex;
        align-items: center;
        margin-top: 4rem;
        justify-content: center;
    }
    .home-prod-header-image::before{
    display: none;
    }
    
    .home-news-image img{
        border-left: solid 15px #dab565;
        border-top: solid 15px #dab565;
        border-bottom: solid 15px #dab565;
    }
    .home-news-image::before{
        display: none;
    }
    .about-header::before{
display: none;
    }

}