.create-form{
    max-width: 600px;
    margin: 4rem 25%;
    border: solid 1px #2c3639;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px #2c3639;
    color: #2c3639;
}
.create-form input ,.create-form textarea{
    text-align: end;
}
.create-news{
    border: solid 1px #2c3639;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 0 10px #2c3639;
    color: #2c3639;
    width: 60vw; 
    margin: 5vh 5vw  ;
    height: 80vh;
}
.create-news input ,.create-news textarea {
    text-align: end;
}

@media only screen and (min-width: 300px) and (max-width: 768px) {

.create-news{
    margin: 0;
    position: relative;
    top: 1rem;
    left: 1.5rem;
    padding: 1rem;
    width: 80vw;
}

}